import * as React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTree,
  faDog,
  faPaw,
  faHotel,
} from "@fortawesome/free-solid-svg-icons";

import Container from "./Container";
import useWindowSize from "../utils/hooks/useWindowSize";

export function ServicesGrid() {
  const { width } = useWindowSize();

  const features = [
    {
      name: "Group Dog Hikes",
      description:
        "Our group hikes are ideal for social and well-trained dogs. In a small pack of other four-legged friends, your pup will enjoy a fun-filled adventure in dog-friendly trails, beaches, and parks.",
      icon: faTree,
      colour: "bg-primary-dark",
    },
    {
      name: "Individual Dog Walks",
      description:
        "Our private walks are ideal for puppies, senior dogs, or dogs that prefer to be the center of attention.",
      icon: faDog,
      colour: "bg-primary",
    },
    {
      name: "Cat/Small Animal Sitting",
      description:
        "We don't just care for dogs! Mrs Paws can help care for your Cat, bird, bunny, or guinea pig while you're away! Never worry about finding pet care for your cat or small pet again!.",
      icon: faPaw,
      colour: width >= 768 ? "bg-primary" : "bg-primary-dark",
    },
    {
      name: "House Sitting",
      description:
        "If you need to travel and a kennel doesn't sound like you or your pets idea of a good time, or you have more than one pet, try our house sitting services instead! Your pet will receive 24/7 care and attention in their own familiar environment.",
      icon: faHotel,
      colour: width >= 768 ? "bg-primary-dark" : "bg-primary",
    },
    {
      name: "Weddings & Special Events",
      description:
        "Has it always been a dream of yours to have your dog be your ring bearer or your Guest of Honour on your Special Day? With our services,your dreams can come true!",
      icon: faHotel,
      colour: width >= 768 ? "bg-primary-dark" : "bg-primary",
    }, 
  ];

  return (
    <Container>
      <div className="lg:text-center">
        <h2 className="text-3xl lg:text-4xl font-bold tracking-tight font-headline text-gray-900 md:text-4xl">
          Some of our Services
        </h2>
        <p className="font-primary text-lg mt-4 max-w-2xl text-gray-600 lg:mx-auto lg:text-xl">
          Whether you are working from home and need someone to exercise your
          dog for you, or you are travelling and need someone to care for your
          pet(s), Mrs Paws Pet Services can help. We offer a variety of services
          and work hard to accommodate your needs and schedule.
        </p>
      </div>

      <div className="mt-10">
        <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
          {features.map((feature) => (
            <div key={feature.name} className="relative">
              <dt>
                <div
                  className={`${feature.colour} absolute flex items-center justify-center h-12 w-12 rounded-md text-white`}
                >
                  <FontAwesomeIcon
                    icon={feature.icon}
                    style={{ width: "1.4rem", height: "1.4rem" }}
                    aria-hidden="true"
                  />
                </div>
                <p className="font-primary ml-16 text-lg leading-6 font-medium text-gray-900">
                  {feature.name}
                </p>
              </dt>
              <dd className="font-primary mt-2 ml-16 text-base text-gray-600">
                {feature.description}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </Container>
  );
}

export default ServicesGrid;
